export interface IISJFields {
  ISJ_ID: string;
  ISJ_DATE: string;
  ISJ_TYPE: string;
  ISJ_CODE: string;
  ISJ_USER: string;
  ISJ_EVENT_CODE: string;
  ISJ_IP: string;
  ISJ_DESCRIPTION: string;
  CREATION_DATE_FROM: string;
  CREATION_DATE_TO: string;
  ISJ_SYSTEM_NAME: string;
  ISJ_SYSTEM_VERSION: string;
  ISJ_OBJECT_ID: string;
  ISJ_OBJECT_TYPE: string;
  ISJ_IS_ID_OPEN_CREATION: string;
  TYPE: string;
  USERNAME: string;
  IP: string;
}

export const fields: IISJFields = {
  ISJ_ID: "id",
  ISJ_IS_ID_OPEN_CREATION: "isIdOpenCreation",
  ISJ_DATE: "eventTime",
  ISJ_TYPE: "eventType",
  ISJ_CODE: "eventCode",
  ISJ_USER: "initiator",
  ISJ_IP: "initiatorIp",
  ISJ_EVENT_CODE: "eventCode",
  ISJ_DESCRIPTION: "message",
  CREATION_DATE_FROM: "eventTimeFrom",
  CREATION_DATE_TO: "eventTimeTo",
  ISJ_SYSTEM_NAME: "systemName",
  ISJ_SYSTEM_VERSION: "systemVersion",
  ISJ_OBJECT_ID: "objectId",
  ISJ_OBJECT_TYPE: "objectType",
  TYPE: "type",
  USERNAME: "username",
  IP: "ip",
};
