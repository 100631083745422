import { Module } from "vuex";
import { IRootState } from "@monorepo/informationSecurity/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { checkExistLibrary, encodeQueryData, getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import { convertFiltersCustomToApi } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/convertFiltersToApi";
import { ISJElement } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/ISJElement";
import { getSelectedIds } from "@monorepo/utils/src/utils/getSelectedIds";
import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertFiltersToApi, IConvertFiltersToApiResponse } from "@monorepo/utils/src/api/convertFiltersToApi";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { ISection, Sections } from "@monorepo/utils/src/types/cellsSections";
import { ISearchTemplate } from "@monorepo/utils/src/types/ISearchTemplate";
import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";
import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";

interface ITableState {
  filters: ITableFiltersObject;
  totalLength: number;
  section?: Sections;
  data: ISJElement[];
  infiniteId: string;
  sectionCells: ISection;
  isOpenFilters: boolean;
  autorefresh: boolean;
  cells: string[];
  searchTemplates: ISearchTemplate[];
  libraries: {
    oiks: string[];
    statuses: string[];
    objectTypes: { code: string; name: string }[];
    eventCodeList: { code: string; name: string }[];
  };
  isTableLoading: boolean;
  isLoadingToggleFilters: boolean;
  isLoadingChangeAutorefresh: boolean;
}

const defaultFilters = (): ITableFiltersObject => ({
  sort: {
    [fields.ISJ_DATE]: SORT_TYPE.DESC,
  },
  openedId: null,
  isSelectAll: false,
  selectedIds: {},
  fieldFilters: {},
  initMessagesLength: 50,
});

const getDefaultState = (): ITableState => ({
  filters: defaultFilters(),
  infiniteId: new Date().toString(),
  totalLength: 0,
  data: [],
  cells: [],
  section: Sections.AUDIT,
  searchTemplates: [],
  sectionCells: {} as ISection,
  isOpenFilters: false,
  autorefresh: false,
  libraries: {
    oiks: [],
    statuses: [],
    eventCodeList: [],
    objectTypes: [],
  },
  isTableLoading: false,
  isLoadingToggleFilters: false,
  isLoadingChangeAutorefresh: false,
});

const resultExportPath = (payload: "PDF" | "CSV" | "XLSX") => {
  switch (payload) {
    case "CSV":
      return QUERY_PATH.GET_AUDIT_CSV_LIST;
    case "XLSX":
      return QUERY_PATH.GET_AUDIT_XLS_LIST;
    default:
      return QUERY_PATH.GET_AUDIT_PDF_LIST;
  }
};

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    ...baseMutations,
    setFilters(state: ITableState, payload: ReturnType<typeof defaultFilters>) {
      state.filters = payload;
    },
    resetState(state: ITableState) {
      Object.assign(state, getDefaultState());
    },
    addStatusesLib(state: ITableState, payload: string[]) {
      state.libraries.statuses = payload || [];
    },
    clearFilters(state: ITableState) {
      state.filters = defaultFilters();
    },
    addEventCodeList(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.eventCodeList = payload;
    },
    addObjectList(state: ITableState, payload: { code: string; name: string }[]) {
      state.libraries.objectTypes = payload;
    },
    addData(
      state: ITableState,
      payload: {
        data: ISJElement[];
        totalLength: number;
        isReset: boolean;
      }
    ) {
      state.data = payload.isReset ? payload.data : state.data.concat(payload.data);
      state.totalLength = payload.totalLength;
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getEventList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const {
        data,
        total: totalLength,
        error,
      } = await getQuery<ISJElement[]>(QUERY_PATH.GET_AUDIT_LIST, params).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0 });
        return { data: state.data || [], totalLength: state.totalLength || 0 };
      }
      return { data: null, error };
    },
    async getISJElement(info, payload: string) {
      const { data } = await getQuery<ISJElement>(`${QUERY_PATH.GET_AUDIT_LIST}/${payload}`);
      return data || {};
    },
    async refreshEventList({ commit, state }) {
      const params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi);
      const { data, total: totalLength } = await getQuery<ISJElement[]>(QUERY_PATH.GET_AUDIT_LIST, {
        ...params,
        offset: 0,
      });

      if (data !== null) {
        commit("addData", { data: data || [], totalLength: totalLength || 0, isReset: true });
      }
      return { data: null };
    },
    async getEventCodes({ commit, state }) {
      checkExistLibrary(state.libraries.eventCodeList, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_EVENT_TYPE_LIB);
        commit("addEventCodeList", data);
      });
    },
    async getObjectTypes({ commit, state }) {
      checkExistLibrary(state.libraries.objectTypes, async () => {
        const { data } = await getQuery<string[]>(QUERY_PATH.GET_AUDIT_OBJECT_TYPE);
        commit("addObjectList", data);
      });
    },
    async getExportData({ state, dispatch, commit }, payload: "CSV" | "PDF" | "XLSX") {
      let params: IConvertFiltersToApiResponse | { ids: string[] };
      const selectedKeys = state.filters.selectedIds ? getSelectedIds(state.filters.selectedIds) : [];
      if (selectedKeys.length && !state.filters.isSelectAll) {
        params = {
          ids: selectedKeys,
        };
      } else {
        params = convertFiltersToApi(state.filters, state.data.length, convertFiltersCustomToApi, true);
      }
      const queryParams = encodeQueryData(params);
      if (payload !== "PDF") {
        dispatch("app/openNewWindow", getFullPath(resultExportPath(payload), queryParams), {
          root: true,
        });
      } else {
        commit("app/setIsExportFileLoading", true, {
          root: true,
        });
        const { data } = await getQuery<void>(QUERY_PATH.GET_AUDIT_PDF_LIST_ASYNC, params);
        if (!data) {
          commit("app/setIsExportFileLoading", false, {
            root: true,
          });
        }
      }
    },
  },
  getters: {
    ...baseGetters,
    eventCodeList(state: ITableState) {
      return state.libraries.eventCodeList;
    },
    objectTypes(state: ITableState) {
      return state.libraries.objectTypes;
    },
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
