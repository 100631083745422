import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";
import { get } from "lodash";

export const openCreationById = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item: Record<string, any>,
  key = "auditObjectType.code",
  idKey = fields.ISJ_OBJECT_ID
): {
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: Record<string, any>;
} | null => {
  switch (get(item ?? {}, key)) {
    case "TK":
      return {
        path: "/inventory/tk",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "USER":
      return {
        path: "/information-security/system-access/users",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "SCREENSHOT_BUG_REPORT":
      return {
        path: "/administration/bug-reports",
        query: { id: get(item ?? {}, idKey) as string, card: get(item ?? {}, idKey) as string },
      };
    case "ROLE":
      return {
        path: "/information-security/system-access/roles",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "AK":
      return {
        path: "/inventory/ak",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "OIK":
      return {
        path: "/dictionaries/oik",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "FUND":
      return {
        path: "/dictionaries/fund",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "EAD":
      return {
        path: "/inventory/ead",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    case "UPDATE_ARCHIVE":
      return {
        path: "/dictionaries/archive",
        query: { id: get(item ?? {}, idKey) as string, isOpenModal: "1" },
      };
    default:
      return null;
  }
};
