export const breadcrumbs = [
  {
    text: "Главная",
    disabled: false,
    href: "/",
  },
  {
    text: "Информационная безопасность: Журнал информационной безопасности",
    disabled: true,
    href: "breadcrumbs_link_1",
  },
];
