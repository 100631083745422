import { v4 as uuid } from "uuid";
import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";
import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";

export const tableHeaders = [
  headerCheckboxObject(),
  {
    text: "Идентификатор",
    tooltip: "Идентификатор события",
    defaultWidth: 100,
    value: fields.ISJ_ID,
    isLink: false,
    isSorted: false,
    isHandle: true,
    isHiddenByDefault: true,
    id: uuid(),
  },
  {
    text: "Время события",
    tooltip: "Время события",
    defaultWidth: 150,
    value: fields.ISJ_DATE,
    isLink: false,
    isSorted: true,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Тип события",
    tooltip: "Типа события может принимать следующие значения: Ошибка, Событие, Действие пользователя",
    defaultWidth: 180,
    value: fields.ISJ_TYPE,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Код события",
    tooltip: "Код события",
    defaultWidth: 400,
    value: fields.ISJ_CODE,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "IP-адрес",
    tooltip: "IP-адрес",
    defaultWidth: 150,
    value: fields.ISJ_IP,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Сообщение",
    tooltip: "Сообщение",
    defaultWidth: 250,
    value: fields.ISJ_DESCRIPTION,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Тип объекта",
    tooltip: "Тип объекта",
    defaultWidth: 180,
    value: fields.ISJ_OBJECT_TYPE,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Идентификатор объекта",
    tooltip: "Идентификатор объекта",
    defaultWidth: 180,
    value: fields.ISJ_OBJECT_ID,
    isSorted: false,
    isHandle: true,
    id: uuid(),
    slotName: "objectId",
  },
  {
    text: "Инициатор",
    tooltip: "Инициатор",
    isLink: false,
    defaultWidth: 180,
    value: fields.ISJ_USER,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Название сервиса",
    tooltip: "Название сервиса",
    defaultWidth: 210,
    value: fields.ISJ_SYSTEM_NAME,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
  {
    text: "Версия сервиса",
    tooltip: "Версия сервиса",
    defaultWidth: 180,
    value: fields.ISJ_SYSTEM_VERSION,
    isSorted: false,
    isHandle: true,
    id: uuid(),
  },
];
