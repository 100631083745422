



























































import { defineComponent } from "@vue/composition-api";
import CardModalInfoElement from "@monorepo/uikit/src/components/tableCardModal/CardModalInfoElement.vue";
import CardModalStatusChip from "@monorepo/uikit/src/components/tableCardModal/CardModalStatusChip.vue";
import { mapActions, mapGetters } from "vuex";
import { ISJElement } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/ISJElement";
import eventBus from "@monorepo/utils/src/eventBus";
import { Route } from "vue-router";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { fullModalElements as modalElements } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/modalElements";
import { get } from "lodash";
import { viewUniqKey } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/viewTitle";
import { openCreationById } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/openCreationById";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { convertApiItemToUi } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/convertApiItemToUi";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { CardMode } from "@monorepo/utils/src/types/cardMode";
import { Sections } from "@monorepo/utils/src/types/cellsSections";
import CardModalToggleViewSize from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleViewSize.vue";
import CardModalToggleTableItems from "@monorepo/uikit/src/components/tableCardModal/CardModalToggleTableItems.vue";
import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";
import { getAuthorityByType } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/getAuthorityByType";
import CardModalChapter from "@monorepo/uikit/src/components/tableCardModal/CardModalChapter.vue";
import useModalChangeByKeypress from "@monorepo/utils/src/composables/useModalChangeByKeypress";
import useUniqueLinkModal from "@monorepo/utils/src/composables/useUniqueLinkModal";
import { ModalType } from "@monorepo/utils/src/variables/modalType";

export default defineComponent({
  name: "InventoryInfoModal",
  components: {
    CardModalInfoElement,
    CardModalStatusChip,
    CardModalToggleViewSize,
    CardModalToggleTableItems,
    CardModalChapter,
  },
  props: {
    type: {
      type: String,
      default: ModalType.EDIT,
    },
  },
  data() {
    return {
      element: {} as ReturnType<typeof convertApiItemToUi>,
      modalElements,
      viewUniqKey,
      openedPanels: [0, 1, 2],
      cardMode: CardMode,
      section: Sections.BUG_REPORT,
      isLoading: false,
      fields,
    };
  },
  watch: {
    openedId: {
      immediate: true,
      async handler(
        this: {
          addQueryOpenedId: () => void;
          getISJElement: (val: string) => Promise<ISJElement>;
          element: ReturnType<typeof convertApiItemToUi>;
          isLoading: boolean;
          isShowAnimation: boolean;
          $route: Route;
        },
        value
      ) {
        if (value) {
          this.isLoading = true;
          await this.addQueryOpenedId();
          this.getISJElement(value)
            .then((data: ISJElement) => {
              this.element = convertApiItemToUi(data || {});
            })
            .catch(console.error)
            .finally(() => {
              this.isLoading = false;
            });
        }
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user", "isShowAnimation", "cardModeList"]),
    ...mapGetters("informationSecurityJournal", ["data", "openedId"]),
    cardModeResult(): CardMode {
      return this.cardModeList[this.section as string] || CardMode.DEFAULT;
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.INFORMATION_SECURITY_JOURNAL_EXPORT);
    },
    getItemElement(): unknown {
      return (key: string) => {
        switch (key) {
          default:
            return get(this.element, key);
        }
      };
    },
    isAuthorityExistInGUID(): boolean {
      const authorities = getAuthorityByType(this.element);
      const authoritiesObject = this.user?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
        result[item.authority || ""] = true;
        return result;
      }, {});
      return !!authorities.find((key: string) => authoritiesObject[key]);
    },
  },
  methods: {
    ...mapActions("informationSecurityJournal", ["changeOpenedId", "getISJElement"]),
    openModal() {
      eventBus.$emit(MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL, formModalPayload(true, "ModalUnderConstruction"));
    },
    openById(event: ReturnType<typeof convertApiItemToUi>, key: string) {
      if (key === fields.ISJ_OBJECT_ID && this.isAuthorityExistInGUID) {
        const path = openCreationById(event);
        if (path) {
          this.$router.push(path);
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
  setup(props, context) {
    const { root } = context;
    useModalChangeByKeypress(root, "informationSecurityJournal", Sections.BUG_REPORT, props.type);
    const { addQueryOpenedId } = useUniqueLinkModal(root, "informationSecurityJournal");

    return {
      addQueryOpenedId,
    };
  },
});
