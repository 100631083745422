import { ISJElement } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/ISJElement";
import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";
import { eventTypes } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/eventTypes";
import moment from "moment";
import { openCreationById } from "./openCreationById";

export const convertApiItemToUi = (item: ISJElement): ISJElement => ({
  ...item,
  [fields.ISJ_TYPE]: eventTypes.find((type) => type.value === item[fields.ISJ_TYPE as keyof ISJElement])?.title,
  [fields.ISJ_CODE]: item.auditEventType ? `${item.auditEventType?.name} (код: ${item.auditEventType?.code})` : "",
  [fields.ISJ_OBJECT_TYPE]: item.auditObjectType?.name || "",
  [fields.ISJ_IS_ID_OPEN_CREATION]: !!openCreationById(item),
  [fields.ISJ_DATE]: moment(item[fields.ISJ_DATE as keyof ISJElement] as string, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD" + " HH:mm"),
});
