





















































































import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import Table from "@monorepo/uikit/src/components/common/Table.vue";
import SortPanelContainer from "@monorepo/uikit/src/components/common/SortPanelContainer.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import ISJInfoModal from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/components/ISJInfoModal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import Footer from "@monorepo/uikit/src/components/common/Footer.vue";
import { tableHeaders } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as informationSecurityJournal } from "@monorepo/informationSecurity/src/store/modules/ISJView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ToggleTableCellsContainer from "./components/ToggleTableCellsContainer.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import AutoRefreshButton from "@monorepo/uikit/src/components/tableViews/AutoRefreshButton.vue";
import useRefreshTable from "@monorepo/utils/src/composables/useRefreshTable";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useSetStartModal from "@monorepo/utils/src/composables/useSetStartModal";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import useSetStartCells from "@monorepo/utils/src/composables/useSetStartCells";
import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";
import useGetTableLibs from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/composables/useGetTableLibs";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import ExportFooterButton from "@monorepo/uikit/src/components/tableViews/ExportFooterButton.vue";
import IdCell from "@monorepo/uikit/src/components/tableViews/IdCell.vue";
import { convertApiItemToUi } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/convertApiItemToUi";
import { getAuthorityByType } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/getAuthorityByType";
import { openCreationById } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/openCreationById";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/utils/defaultCurrentFilters";
import { convertToSelectItems } from "@monorepo/utils/src/utils/convertToSelectItems";
import { eventTypes } from "@monorepo/informationSecurity/src/views/InformationSecurityJournal/types/eventTypes";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "informationSecurityJournal";

export default defineComponent({
  name: "InformationSecurityJournalView",
  components: {
    TableSettings,
    ExportButton,
    ToggleTableCellsContainer,
    Table,
    Filters,
    InfiniteScroll,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
    SortPanelContainer,
    ISJInfoModal,
    AutoRefreshButton,
    Footer,
    ExportFooterButton,
    IdCell,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      showEadModal: false,
      pluralFormTitles: ["Событие", "События", "Событий"],
      fields,
      tableHeaders,
      convertApiItemToUi,
      filtersElements,
      setCurrentFilters,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation", "user"]),
    ...mapGetters(SECTION_NAME, [
      "cells",
      "isOpenFilters",
      "openedId",
      "isSelectAll",
      "selectedIds",
      "infiniteId",
      "data",
      "totalLength",
      "isTableLoading",
      "autorefresh",
      "isLoadingChangeAutorefresh",
      "eventCodeList",
      "objectTypes",
    ]),
    eventCodeListSelectItems(): { title: string; value: string }[] {
      return (this.eventCodeList || []).map((item: { code: string; name: string }) => ({
        title: `${item.name} (код: ${item.code})`,
        value: item.code,
      }));
    },
    eventTypesListSelectItems(): { title: string; value: string }[] {
      return convertToSelectItems(this.objectTypes, "name", "code");
    },
    selectItemsObj(): Record<string, unknown> {
      return {
        eventCodeListSelectItems: this.eventCodeListSelectItems,
        eventTypesListSelectItems: this.eventTypesListSelectItems,
        eventTypes,
      };
    },
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.INFORMATION_SECURITY_JOURNAL_EXPORT);
    },
    isShowFooter(): boolean {
      return (this.isSelectAllElement || !!Object.values(this.selectedIds).filter(Boolean).length) && this.isShowExport;
    },
    isShowModal(): boolean {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
    headers(): typeof tableHeaders {
      return tableHeaders;
    },
    isAuthorityExist() {
      return (event: ReturnType<typeof convertApiItemToUi>) => {
        const authorities = getAuthorityByType(event);
        const authoritiesObject = this.user?.authorities.reduce((result: { [key: string]: boolean }, item: { authority: string }) => {
          result[item.authority || ""] = true;
          return result;
        }, {});
        return !!authorities.find((key: string) => authoritiesObject[key]);
      };
    },
    isSelectAllElement: {
      get(): boolean {
        return this.isSelectAll;
      },
      set(value: boolean) {
        this.$store.dispatch(`${SECTION_NAME}/setIsSelectAll`, value);
      },
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters", "setAutorefresh"]),
    openModalIfNeed() {
      if (this.$route.query?.isOpenModal && this.data.length) {
        this.addOpenedId(this.data[0].id);
      } else if (this.$route.query?.card && this.data.length) {
        this.addOpenedId(this.$route.query?.card as string);
      }
    },
    toggleModal(value: boolean) {
      if (!value) {
        this.$store.dispatch(`${SECTION_NAME}/addOpenedId`, null);
      }
    },
    setCells(value: { value: string }[]) {
      this.$store.dispatch(
        `${SECTION_NAME}/setCells`,
        value.map((item) => item.value)
      );
    },
    openById(event: ReturnType<typeof convertApiItemToUi>) {
      if (!this.isAuthorityExist(event)) {
        return;
      }
      const path = openCreationById(event);
      if (path) {
        this.$router.push(path);
      }
    },
    openModal({ header, event }: { header: { value: string; isLink: boolean }; event: Record<string, unknown> }) {
      switch (header.value) {
        case "openModal":
          if (event[fields.ISJ_ID]) {
            this.$store.dispatch("informationSecurityJournal/addOpenedId", event.id);
            this.toggleModal(true);
          }
          break;
        default:
          break;
      }
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, informationSecurityJournal as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartCells(root, SECTION_NAME, root.$store.getters[`${SECTION_NAME}/section`], tableHeaders, true).then(() => {
      useRefreshTable(root, SECTION_NAME);
    });

    useSetStartFilters(root, SECTION_NAME, [fields.ISJ_USER]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
      useSetStartModal(root, SECTION_NAME);
    });
    useGetTableLibs(root);
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
