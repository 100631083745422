import { fields } from "@monorepo/utils/src/variables/projectsData/InformationSecurityJournal/fields";

export const filtersElements = [
  {
    value: fields.ISJ_TYPE,
    title: "Тип",
    type: "select",
    selectItemsField: "eventTypes",
    width: 263,
    className: "filters__isj-type mt-4",
  },
  {
    value: fields.ISJ_DATE,
    title: "Период",
    dateValues: [fields.CREATION_DATE_FROM, fields.CREATION_DATE_TO],
    type: "datePeriod",
    width: 244,
  },
  {
    value: fields.ISJ_USER,
    title: "Пользователь",
    type: "text",
    width: 263,
  },
  {
    value: fields.ISJ_IP,
    title: "IP-адрес",
    type: "text",
    width: 386,
  },
  {
    value: fields.ISJ_EVENT_CODE,
    title: "Код события",
    type: "select",
    selectItemsField: "eventCodeListSelectItems",
    width: 386,
  },
  {
    value: fields.ISJ_OBJECT_ID,
    title: "Идентификатор объекта",
    type: "text",
    width: 263,
  },
  {
    value: fields.ISJ_OBJECT_TYPE,
    title: "Тип объекта",
    type: "select",
    selectItemsField: "eventTypesListSelectItems",
    width: 386,
  },
];
